import {
  IdentityVerificationSheetEventsEnum,
  StripeIdentity,
  type StripeIdentityError,
} from "@capacitor-community/stripe-identity";
import { isDefined } from "@clipboard-health/util-ts";
import { environmentConfig } from "@src/appV2/environment";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { getStripe, STRIPE_ERROR_MESSAGE } from "./getStripe";
import { StripeIdentityVerificationFlowStatus } from "./types";

interface StartStripeIdentitySessionProps {
  verificationSessionId: string;
  ephemeralKeySecret?: string;
  clientSecret: string;
}

interface RemoveStripeIdentitySessionListenersProps {
  removeIdentitySessionLoadedListener: () => Promise<void>;
  removeIdentitySessionFailedToLoadListener: () => Promise<void>;
  removeIdentitySessionCompletedListener: () => Promise<void>;
  removeIdentitySessionCanceledListener: () => Promise<void>;
  removeIdentitySessionFailedListener: () => Promise<void>;
}

async function removeStripeIdentitySessionListeners(
  listenerRemovalHandlers: RemoveStripeIdentitySessionListenersProps
) {
  const {
    removeIdentitySessionLoadedListener,
    removeIdentitySessionFailedToLoadListener,
    removeIdentitySessionCompletedListener,
    removeIdentitySessionCanceledListener,
    removeIdentitySessionFailedListener,
  } = listenerRemovalHandlers;

  await removeIdentitySessionLoadedListener();
  await removeIdentitySessionFailedToLoadListener();
  await removeIdentitySessionCompletedListener();
  await removeIdentitySessionCanceledListener();
  await removeIdentitySessionFailedListener();
}

async function setupStripeIdentitySessionListeners(verificationSessionId: string) {
  const { remove: removeIdentitySessionLoadedListener } = await StripeIdentity.addListener(
    IdentityVerificationSheetEventsEnum.Loaded,
    () => {
      logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_EVENT, {
        stripe_identity_event: "Loaded",
        verificationSessionId,
      });
    }
  );

  const { remove: removeIdentitySessionFailedToLoadListener } = await StripeIdentity.addListener(
    IdentityVerificationSheetEventsEnum.FailedToLoad,
    (info: StripeIdentityError) => {
      logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_EVENT, {
        stripe_identity_event: "Failed To Load",
        verificationSessionId,
        message: info.message,
      });
    }
  );

  const { remove: removeIdentitySessionCompletedListener } = await StripeIdentity.addListener(
    IdentityVerificationSheetEventsEnum.Completed,
    () => {
      logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_EVENT, {
        stripe_identity_event: "Completed",
        verificationSessionId,
      });
    }
  );

  const { remove: removeIdentitySessionCanceledListener } = await StripeIdentity.addListener(
    IdentityVerificationSheetEventsEnum.Canceled,
    () => {
      logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_EVENT, {
        stripe_identity_event: "Canceled",
        verificationSessionId,
      });
    }
  );

  const { remove: removeIdentitySessionFailedListener } = await StripeIdentity.addListener(
    IdentityVerificationSheetEventsEnum.Failed,
    (info: StripeIdentityError) => {
      logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_EVENT, {
        stripe_identity_event: "Failed",
        verificationSessionId,
        message: info.message,
      });
    }
  );

  return {
    removeStripeIdentitySessionListeners: async () => {
      await removeStripeIdentitySessionListeners({
        removeIdentitySessionLoadedListener,
        removeIdentitySessionFailedToLoadListener,
        removeIdentitySessionCompletedListener,
        removeIdentitySessionCanceledListener,
        removeIdentitySessionFailedListener,
      });
    },
  };
}

async function startStripeIdentitySessionWithWebView(clientSecret: string) {
  const stripe = await getStripe();
  if (!stripe) {
    throw new Error(STRIPE_ERROR_MESSAGE);
  }

  const { error } = await stripe.verifyIdentity(clientSecret);

  if (error) {
    if (["session_cancelled", "consent_declined"].includes(error?.code ?? "")) {
      return StripeIdentityVerificationFlowStatus.CANCELED;
    }

    return StripeIdentityVerificationFlowStatus.FAILED;
  }

  return StripeIdentityVerificationFlowStatus.COMPLETED;
}

export async function startStripeIdentitySession(
  props: StartStripeIdentitySessionProps
): Promise<StripeIdentityVerificationFlowStatus> {
  const { verificationSessionId, ephemeralKeySecret, clientSecret } = props;

  if (!isDefined(ephemeralKeySecret)) {
    return await startStripeIdentitySessionWithWebView(clientSecret);
  }

  if (!isCapacitorPlatform()) {
    await StripeIdentity.initialize({
      publishableKey: environmentConfig.REACT_APP_STRIPE_PUBLIC_KEY,
    });
  }

  // Ensure that the listeners are set up before creating the session
  const { removeStripeIdentitySessionListeners } = await setupStripeIdentitySessionListeners(
    verificationSessionId
  );

  try {
    await StripeIdentity.create({
      ephemeralKeySecret,
      verificationId: verificationSessionId,
      ...(isCapacitorPlatform() ? {} : { clientSecret }), // parameter only needed for Web app
    });
  } catch (error) {
    // Ensure the listeners are cleaned up even if creation fails
    await removeStripeIdentitySessionListeners();
    // Handle the error appropriately
    logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_EVENT, {
      stripe_identity_event: "Error on create",
      verificationSessionId,
      error,
    });
    return StripeIdentityVerificationFlowStatus.FAILED;
  }

  try {
    const result = await StripeIdentity.present();

    await removeStripeIdentitySessionListeners();

    switch (result.identityVerificationResult) {
      case IdentityVerificationSheetEventsEnum.Completed: {
        logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_RESULT, {
          stripe_identity_result: "Completed",
          verificationSessionId,
        });
        return StripeIdentityVerificationFlowStatus.COMPLETED;
      }

      case IdentityVerificationSheetEventsEnum.Canceled: {
        logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_RESULT, {
          stripe_identity_result: "Canceled",
          verificationSessionId,
        });
        return StripeIdentityVerificationFlowStatus.CANCELED;
      }

      default: {
        logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_RESULT, {
          stripe_identity_result: "Failed",
          verificationSessionId,
        });
        return StripeIdentityVerificationFlowStatus.FAILED;
      }
    }
  } catch (error) {
    // Ensure the listeners are cleaned up even if presenting fails
    await removeStripeIdentitySessionListeners();
    // Handle the error appropriately
    logEvent(APP_V2_APP_EVENTS.STRIPE_IDENTITY_SDK_SESSION_EVENT, {
      stripe_identity_event: "Error on present",
      verificationSessionId,
      error,
    });
    return StripeIdentityVerificationFlowStatus.FAILED;
  }
}
