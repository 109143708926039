import { startOfMinute } from "date-fns";
import { z } from "zod";

/**
 * Describes the parameters for the getAgentStreaks API.
 * The `transforms` found in this schema do the work of converting
 * the input parameters (human-readable) into the format that are
 * optimal for the API (machine-readable).
 */
export const getAgentStreaksParamsSchema = z.object({
  agentId: z.string(),
  filter: z
    .object({
      date: z
        .date()
        .optional()
        // This transform clamps seconds, and millis to 0 to prevent re-rendering
        // when passing in new Date() as the default value
        .transform((date) => {
          if (date) {
            return startOfMinute(date);
          }

          return date;
        })
        .transform((date) => date?.toISOString()),
      facilityIds: z
        .array(z.string())
        .optional()
        .transform((facilityIds) => facilityIds?.join(",")),
    })
    .optional(),
});

// Similar to streaks params schema, but for bonuses, the date is not optional
export const getAgentStreaksBonusParamsSchema = z.object({
  agentId: z.string(),
  filter: z
    .object({
      date: z
        .date()
        // This transform clamps seconds, and millis to 0 to prevent re-rendering
        // when passing in new Date() as the default value
        .transform((date) => {
          if (date) {
            return startOfMinute(date);
          }

          return date;
        })
        .transform((date) => date?.toISOString()),
      facilityIds: z
        .array(z.string())
        .optional()
        .transform((facilityIds) => facilityIds?.join(",")),
    })
    .optional(),
});

/**
 * Describes the request schema for the getFacilityStreaks API that the
 * endpoint expects.
 */
export const getAgentStreaksRequestSchema = z.object({
  filter: z
    .object({
      date: z.string().optional(),
      facilityIds: z.string().optional(),
    })
    .optional(),
});

export const getAgentStreaksBonusRequestSchema = z.object({
  filter: z
    .object({
      date: z.string(),
      facilityIds: z.string().optional(),
    })
    .optional(),
});

export enum StreakStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum AgentStreakProgressState {
  NOT_ENABLED_AT_FACILITY = "NOT_ENABLED_AT_FACILITY",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}

/**
 * Describes the schema for the streak object that the getFacilityStreaks
 * API returns.
 */
export const streakSchema = z.object({
  id: z.string(),
  type: z.literal("STREAKS"),
  attributes: z.object({
    agentId: z.string(),
    facilityId: z.string(),
    validityInterval: z.object({
      from: z.string(),
      to: z.string(),
    }),
    status: z.nativeEnum(StreakStatus),
    minHoursForActiveStreak: z.number(),
    payBoostEnabled: z.boolean(),
    payBoostPercentage: z.number(),
  }),
});

export const agentStreaksProgressSchema = z.object({
  type: z.literal("streak-progress"),
  attributes: z.object({
    agentId: z.string(),
    facilityId: z.string(),
    facilityName: z.string(),
    validityInterval: z.object({
      from: z.string(),
      to: z.string(),
    }),
    status: z.nativeEnum(AgentStreakProgressState),
    minHoursForActiveStreak: z.number(),
    verifiedHoursInStreakPeriod: z.number(),
    unverifiedHoursInStreakPeriod: z.number(),
    payBoostPercentage: z.number(),
  }),
});

export const agentStreaksBonusSchema = z.object({
  type: z.literal("streak-bonus"),
  attributes: z.object({
    agentId: z.string(),
    validityInterval: z.object({
      from: z.string(),
      to: z.string(),
    }),
    totalBonusPay: z.number(),
  }),
});

export const getStreakAlertMessageSchema = z.object({
  shiftStartTime: z.date().optional(),
  shiftEndTime: z.date().optional(),
  workerId: z.string(),
  workplaceId: z.string(),
  onlyCheckActiveStreak: z.boolean(),
  isOpenShift: z.boolean().optional(),
  isForFacilityOpenShift: z.boolean().optional(),
});

export const getAgentStreaksProgressResponseSchema = z.object({
  data: z.array(agentStreaksProgressSchema),
});

export const getAgentStreaksResponseSchema = z.object({
  data: z.array(streakSchema),
});

export const getAgentStreaksBonusResponseSchema = z.object({
  data: agentStreaksBonusSchema,
});

export type Streak = z.infer<typeof streakSchema>;
export type StreakProgress = z.infer<typeof agentStreaksProgressSchema>;
export type StreaksBonus = z.infer<typeof agentStreaksBonusSchema>;

export type GetAgentStreaksParams = z.input<typeof getAgentStreaksParamsSchema>;
export type GetAgentStreaksProgressParams = z.input<typeof getAgentStreaksParamsSchema>; // re-using same schema
export type GetAgentStreaksBonusParams = z.input<typeof getAgentStreaksBonusParamsSchema>;

export type AgentStreaksAPIResponse = z.input<typeof getAgentStreaksResponseSchema>;
export type AgentStreaksProgressApiResponse = z.input<typeof getAgentStreaksProgressResponseSchema>;
export type AgentStreaksBonusApiResponse = z.input<typeof getAgentStreaksBonusResponseSchema>;

export type GetStreakAlertMessage = z.input<typeof getStreakAlertMessageSchema>;
