/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */

import { isDefined } from "@clipboard-health/util-ts";
import { environmentConfig } from "@src/appV2/environment";
import { loadStripe, type Stripe } from "@stripe/stripe-js";

export const STRIPE_ERROR_MESSAGE = "Could not start Stripe session. Please try again later.";
let stripe: Stripe | undefined;

export async function getStripe(): Promise<Stripe> {
  if (!isDefined(stripe)) {
    /**
     * FIXME - `loadStripe` requires a string as the first argument, but we have historically called
     * `loadStripe` with undefined via the Bitrise build.
     * Somehow, this worked.
     * We need to investigate why this worked. We also observed that if we pass in empty string
     * aka: `""`, then `loadStripe` throws an exception, breaking the app.
     * All of this should be investigated.
     * In addition, for some reason the Android scheme for our application is running as
     * `http`, and Stripe requires the app to run as `https`. For this reason, the `stripePublicKey`
     * is undefined in the device specific build.
     * Should we consider adding a guard for `isAndroidPlatform`?
     * Ideally, we do not coerce `undefined` to a `string`, but instead, guard, by something like:
     * if (!isDefined(stripe) && isDefined(stripePublicKey)) {
     */
    stripe = (await loadStripe(environmentConfig.REACT_APP_STRIPE_PUBLIC_KEY)) ?? undefined;

    if (!isDefined(stripe)) {
      throw new Error(STRIPE_ERROR_MESSAGE);
    }
  }

  return stripe;
}
